import React from 'react'; 
import { ProjectPage } from './ProjectPage';

const images = [
   {
      src: '',
   },
]

const PJShanesChat = () => {
   return <ProjectPage title="Shane's Chat" images={images}/>;
};

export default PJShanesChat;
