export const projects = [
    {
       title: 'AI Learns to Drive',
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/ai-learns-to-drive',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Live Demo',
             link: '/',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
    {
       title: '3D Rendering Engine',
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/3d-rendering-engine',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/DEMO_3D',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Download',
             link: 'https://drive.google.com/drive/folders/1MG5rai0nvQjz_sweQyv6sNwxut0zVaFz?usp=sharing',
             target: '_blank',
             icon: 'fa-solid fa-download',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
    {
       title: 'Optical Character Recognition',
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/optical-character-recognition',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/OpticalCharacterRecognition',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Live Demo',
             link: 'https://opticalcharacterrecognition.onrender.com/',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
    {
       title: "Shane's Chat",
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/shanes-chat',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/shanes.chat',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Live Demo',
             link: 'https://www.shanes.chat',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
    {
       title: 'Spotify Tracker',
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/spotify-tracker',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Live Demo',
             link: '/',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
    {
       title: 'Chess',
       buttons: [
          {
             label: 'Gallery',
             link: '/projects/p/chess',
             target: '_self',
             icon: 'fa-solid fa-arrow-right',
             clr1: '#0082b5',
             clr2: '#00b7ff',
          },
          {
             label: 'GitHub',
             link: 'https://github.com/ItsMeShane/Chess_Engine',
             target: '_blank',
             icon: 'fa-solid fa-arrow-up-right-from-square',
             clr1: '#545994',
             clr2: '#0015ff',
          },
          {
             label: 'Download',
             link: 'https://drive.google.com/drive/folders/1pVmdou1kw2y4AhkqtpiVMmmTOnrkGcHR?usp=sharing',
             target: '_blank',
             icon: 'fa-solid fa-download',
             clr1: '#6e5494',
             clr2: '#6600ff',
          },
       ],
    },
 ];