import React from 'react';
import { ProjectPage } from './ProjectPage';

const images = [
   {
      src: '',
   },
]

const PJChess = () => {
   return <ProjectPage title='Chess' images={images}/>;
};

export default PJChess;
